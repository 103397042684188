import styled, { css } from 'styled-components';

interface IBodyItem {
  selected: boolean;
  rounded: string;
}

interface ShadowControl {
  haveShadow: boolean;
}

enum BoxShadowKey {
  light = 'light',
}

const boxShadow = {
  [BoxShadowKey.light]: css`
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.15);
  `,
};

export const Container = styled.div``;

export const Header = styled.div<ShadowControl>`
  display: flex;
  align-items: center;

  position: sticky;
  top: 0;
  gap: 40px;
  z-index: 99;
  width: 100%;
  min-width: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ haveShadow, theme }) => haveShadow && boxShadow[theme.title]}

  @media only screen and (min-width: 426px) {
    gap: 48px
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: 70px;
  min-height: 44px;
  word-break: keep-all;
  background-color: ${({ theme }) => theme.colors.white};

  :first-child {
    position: sticky;
    left: 0;
  }

  @media only screen and (min-width: 426px) {
    min-height: 87px;
    min-width: 128px;
    max-width: 128px;
  }
`;

export const HeaderText = styled.p`
  color: ${({ theme }) => theme.colors.gray400};
  text-align: center;
  font-style: normal;
  font-size: 10px;
  line-height: normal;

  @media only screen and (min-width: 426px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray400};
  text-align: left;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: normal;

  @media only screen and (max-width: 425px) {
    text-align: center;
  }

  @media only screen and (min-width: 426px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  word-break: keep-all;
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: 70px;
  min-height: 44px;
  
  background-color: ${({ theme }) => theme.colors.gray100};

  &:last-child {
    border-radius: 4px;
  }
  &:first-child {
    border-radius: 4px;
  }

  @media only screen and (min-width: 426px) {
    min-height: 87px;
    min-width: 128px;
    max-width: 128px;
  }
`;

interface IContentRow {
  valid: boolean;
}

export const ContentRow = styled.div<IContentRow>`
  display: flex;
  width: 100%;
  gap: 40px;
  min-width: fit-content;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray100};
  border: solid 1px transparent;

  &:first-child {
    margin-top: 1px;
  }

  ${props =>
    props.valid &&
    css`
      border: solid 1px ${({ theme }) => theme.colors.statusError};
    `}

  @media only screen and (min-width: 426px) {
    gap: 48px;
    margin-bottom: 16px;
  }
`;

export const ContentRowLine = styled.div<ShadowControl>`
  position: sticky;
  left: 0;
  border-radius: 4px 0 0 4px;
  min-width: 70px;
  max-width: 70px;
  min-height: 44px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.gray100};

  display: flex;
  align-items: center;
  justify-content: center;
  transition: linear 0.1s;
  ${({ haveShadow, theme }) => haveShadow && boxShadow[theme.title]}

  @media only screen and (min-width: 426px) {
    min-height: 87px;
    min-width: 128px;
    max-width: 128px;
  }
`;

export const RowLineCheck = styled.div<IBodyItem>`
  border-radius: ${({ rounded }) => rounded};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .square-selected {
    display: ${({ selected }) => selected ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};

    &:after {
      content: "";
      display: ${({ selected }) => selected ? 'block' : 'none'};
      margin-top: -3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  .circle-selected {
    display: ${({ selected }) => selected ? 'block' : 'none'};
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ rounded }) => rounded};
  }
  
  ${props =>
    props.selected &&
    css`
      border: 1.5px solid ${({ theme }) => theme.colors.primary};
    `}

  @media only screen and (min-width: 426px) {
    height: 27px;
    width: 27px;

    .circle-selected {
      width: 9px;
      height: 9px;
    }

    .square-selected {
      &:after {
        width: 7px;
        height: 15px;
        border-width: 0 3px 3px 0;
      }
    }

    ${props =>
      props.selected &&
      css`
        border: 2px solid ${({ theme }) => theme.colors.primary};
      `}
  }
`;
