import {
  Alternativa,
  Pergunta,
  TipoAlternativa,
  TituloAlternativa
} from 'core/pesquisa/Pesquisa';
import CoreContext from 'providers/core';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { PerguntaRef, genericRespostaAtual } from '../../interfaces';
import {
  Container,
  Content,
  ContentItem,
  ContentRow,
  ContentRowLine,
  Header,
  HeaderItem,
  HeaderText,
  RowLineCheck,
  Text
} from '../styles';

interface Props {
  pergunta: Pergunta;
  respostaAtual: (id: number) => genericRespostaAtual;
  shadowControl: { scrollX: boolean; scrollY: boolean };
}

interface AlternativaSelecionada {
  alternativa: Alternativa;
  tituloAlternativa: TituloAlternativa;
}

const PerguntaGradeUnicaReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const {
    pergunta,
    respostaAtual,
    shadowControl: { scrollX, scrollY },
  } = props;
  const { errosValidationQuestions } = useContext(CoreContext);

  const linhas = pergunta?.definicoes?.titulo_alternativas ?? [];
  const colunas = pergunta?.alternativas ?? [];

  const [alternativasSelecionadas, setAlternativasSelecionadas] = useState<
    AlternativaSelecionada[]
  >([]);

  const handleAlternativaClick = ({
    alternativa,
    tituloAlternativa,
  }: AlternativaSelecionada) => {
    const alternativaPreviamenteSelecionadaIndex =
      alternativasSelecionadas.findIndex(
        alternativaSelecionada =>
          alternativaSelecionada.alternativa.id === alternativa.id &&
          alternativaSelecionada.tituloAlternativa.id === tituloAlternativa.id,
      );
    if (alternativaPreviamenteSelecionadaIndex === -1) {
      setAlternativasSelecionadas([
        ...alternativasSelecionadas,
        { alternativa, tituloAlternativa },
      ]);

      // pega os itens da linha atual
      const linhaAtual = alternativasSelecionadas.filter(
        ele => ele.tituloAlternativa.id === tituloAlternativa.id,
      );

      // guarda os valores das outras linhas
      const outrasLinhas = alternativasSelecionadas.filter(
        ele => ele.tituloAlternativa.id !== tituloAlternativa.id,
      );

      if (linhaAtual.length >= 1) {
        // é mesclado os arrays
        // removendo a primeira posição da linha selecionada
        const novasAlternativasSelecionadas = [
          ...linhaAtual.filter(el => ![linhaAtual[0]].includes(el)),
          ...outrasLinhas,
        ];

        setAlternativasSelecionadas([
          ...novasAlternativasSelecionadas,
          { alternativa, tituloAlternativa },
        ]);
      }
    } else {
      setAlternativasSelecionadas([
        ...alternativasSelecionadas.filter(
          (alternativaSelecionada, index) =>
            index !== alternativaPreviamenteSelecionadaIndex,
        ),
      ]);
    }
  };

  useImperativeHandle(ref, () => ({
    alternativas: null,
    data: null,
    texto: null,
    foto: null,
    alternativas_selecionadas: alternativasSelecionadas.map((item, index) => ({
      id_alternativa: item.alternativa.id,
      id_titulo_alternativa: item.tituloAlternativa.id,
      ordem_selecao: index + 1,
      tipo_alternativa: TipoAlternativa.GRADE,
    })),
    alternativas_selecionadas_gradeLocal: alternativasSelecionadas,
    destinoPulo: null,
    horario: null,
    vazia: alternativasSelecionadas.length === 0,
  }));

  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);

    if (respostaLocalAtual && respostaLocalAtual.estadoAtual) {
      setAlternativasSelecionadas(respostaLocalAtual.estadoAtual);
    }
  }, [pergunta.id, respostaAtual]);

  return (
    <Container>
      <Header haveShadow={scrollY}>
        <HeaderItem />
        {colunas.map(coluna => (
          <HeaderItem key={`${coluna.id}${coluna.enunciado}`}>
            <HeaderText>{coluna.enunciado}</HeaderText>
          </HeaderItem>
        ))}
      </Header>
      <Content>
        {linhas.map(linha => (
          <ContentRow
            key={`${linha.id}${linha.enunciado}`}
            valid={
              !!errosValidationQuestions.length &&
              alternativasSelecionadas.filter(
                item => item.tituloAlternativa.id === linha.id,
              ).length < (pergunta.definicoes?.numero_minimo_respostas ?? 0)
            }
          >
            <ContentRowLine haveShadow={scrollX}>
              <Text>{linha.enunciado}</Text>
            </ContentRowLine>
            {colunas.map(coluna => (
              <ContentItem key={`${coluna.id}${linha.id}${coluna.enunciado}`}>
                <RowLineCheck
                  rounded="50%"
                  selected={alternativasSelecionadas?.some(
                    alternativaSelecionada =>
                      alternativaSelecionada.alternativa.id === coluna.id &&
                      alternativaSelecionada.tituloAlternativa.id === linha.id,
                  )}
                  onClick={() => {
                    handleAlternativaClick({
                      tituloAlternativa: linha,
                      alternativa: coluna,  
                    });
                  }}
                >
                  <div className='circle-selected' />
                </RowLineCheck>
              </ContentItem>
            ))}
          </ContentRow>
        ))}
      </Content>
    </Container>
  );
};
const PerguntaGradeUnica = forwardRef(PerguntaGradeUnicaReferenciable);
export { PerguntaGradeUnica };
